<template>
  <div class="BottomNavbarBox">
    <el-row style="margin: 0;" :gutter="10" type="flex" justify="center">
      <!-- 自适应布局 -->
      <el-col :xs="24" :sm="18" :md="12" :lg="8" :xl="8">
        <van-tabbar route :border="false" v-model="active" @change="onChange">
          <van-tabbar-item replace to="/home">
            <template #icon="props">
                <img class="img_box" :src="props.active ? icon.inHome : icon.home" />
            </template>
          </van-tabbar-item>
<!--          <van-tabbar-item replace to="/game">-->
<!--            <template #icon="props">-->
<!--                <img class="img_box" :src="props.active ? icon.inGame : icon.game" />-->
<!--            </template>-->
<!--          </van-tabbar-item>-->
          <van-tabbar-item replace to="/order">
            <template #icon="props">
              <img class="img_box" :src="props.active ? icon.inOrder : icon.order" />
            </template>
          </van-tabbar-item>
          <van-tabbar-item replace to="/vip">
            <template #icon="props">
                <img class="img_box" :src="props.active ? icon. vip: icon.noVip" />
            </template>
          </van-tabbar-item>
          <van-tabbar-item replace to="/user">
            <template #icon="props">
                <img class="img_box" :src="props.active ? icon.inUser : icon.user" />
            </template>
          </van-tabbar-item>
        </van-tabbar>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "BottomNavbar",
  props: {},
  data() {
    return {
      active: "home",
      icon: {
        home: require("../../assets/iconfont/home.svg"),
        inHome: require("../../assets/iconfont/inHome.svg"),
        game: require("../../assets/iconfont/game.svg"),
        inGame: require("../../assets/iconfont/inGame.svg"),
        order: require("../../assets/iconfont/order.svg"),
        inOrder: require("../../assets/iconfont/inOrder.svg"),
        vip: require("../../assets/iconfont/vip.svg"),
        noVip: require("../../assets/iconfont/noVip.svg"),
        user: require("../../assets/iconfont/user.svg"),
        inUser: require("../../assets/iconfont/inUser.svg"),
      },
    };
  },
  components: {},
  methods: {
    onChange(index) {
      console.log("tabbar",this.active, index )
    },
  },
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.BottomNavbarBox {
  position: fixed;
  bottom: 0%;
  width: 100%;
  z-index: 1000;
  .el-col{
    position: relative;
    .van-tabbar{
      position: absolute;
      height: 4.084rem !important;
      border-top: 1px solid #fff0;
      background-color: #262f38;
      border-radius: 1.75rem;
      backdrop-filter: blur(0.29rem);
      .van-tabbar-item--active{
        color: unset !important; 
        background-color: unset !important;
      }
    }
  }
}
.img_box{
    widows: 24px;
    height: 24px;
}
</style>
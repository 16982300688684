import service from '@/utils/request.js'
import service5 from '@/utils/request5.js'
import service6 from '@/utils/request6.js'

export default {
    // 获取购买VIP赠送积分配置
    getBuyVipGiveConfig() {
        return service.post('/app/getBuyVipGiveConfig')
    },
    // 获取所有VIP赠送释放积分信息
    getAllVipGiveUnlockInfo() {
        return service.post('/app/getAllVipGiveUnlockInfo')
    },
    // 刷新某个VIP赠送释放积分信息
    getVipGiveUnlockInfo(params) {
        return service.post('/app/getVipGiveUnlockInfo', params)
    },
    // 开启赠送释放积分
    openVipGive(params) {
        return service.post('/app/openVipGive', params)
    },
    // 赠送释放积分转化为可用U
    transformVipGive(params) {
        return service.post('/app/transformVipGive', params)
    },
    // 获取每笔转化手续费
    getPerTransformFee() {
        return service.post('/app/getPerTransformFee')
    },
    // 获取铸造、销毁图表数据
    getMintBurnInfo(params) {
        // return service.post('/app/token/getKLine', params)
        return service6.post('/token/getKLine', params)
    },

    // -------- 竞拍 -------
    // 获取竞拍行信息
    getAuctionInfo() {
        return service5.post('/auction/app/getAuctionInfo')
    },
    // 预约
    reservation(params) {
        return service.post('/app/auction/reservation', params)
    },
    // 抢单
    lockOrder(params) {
        return service5.post('/auction/app/lockOrder', params)
    },
    // 获取不同状态的订单
    getAuctionOrders(params) {
        return service5.post('/auction/app/getOrders', params)
    },
    // 获取已完成的订单列表
    getAuctionTradeds(params) {
        return service5.post('/auction/app/getTradeds', params)
    },
    // 寄售
    auctionShelves(params) {
        return service.post('/app/auction/shelves', params)
    },
    // 获取竞拍行团队
    getAuctionTeam(params) {
        return service5.post('/app/auction/getAuctionTeam', params)
    },
    // 确认或取消抢单(10秒倒计时)
    confirmAuctionOrder(params) {
        return service5.post('/auction/app/confirmOrder', params)
    },
    // 支付竞拍单
    purchase(params) {
        return service.post('/app/auction/purchase', params)
    },
    // 获取所有币释放信息
    getAllProductTokenInfo() {
        return service.post('/app/auction/getAllProductTokenInfo')
    },

    // 创建订单
    createOrder(params) {
        return service6.post('/order/create', params)
    },

    orderAdd(params) {
        return service6.post('/order/add', params)
    },

    confirmOrder(params){
        return service6.post('/order/confirm', params)
    },

    // 订单详细
    orderDetail(params) {
        return service6.post('/order/detail', params)
    },
	// 订单列表
	orderLists(params) {
	    return service6.post('/order/lists', params)
	},
}
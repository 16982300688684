export default {
  route: {},
  TabPane: {
    details: "详情",
    wallet: "钱包",
    copy: "复制成功",
  },
  vote: {
    timeRemaining: "剩余时间",
    timeRemaining2: "延时进行中",
    timeRemaining3: "结算中",
    over: "已结束",
    trend: "趋势",
    debate: "辩论",
    votePeople: "辩论人数",
    jackpot: "奖池",
    IntegralConsumption: "积分消耗:",
    hot: "热门",
    new: "新的",
    noReason: "无理由",
    allReason: "所有理由",
    noMore: "没有更多",
    vote1: "辩论",
    vote2: "请输入票数",
    vote3: "辩论后无法撤销",
    vote4: "辩论成功",
    vote5: "请输入理由",
    vote6: "点赞",
  },
  wallet: {
    link: "连接钱包到Plouto",
    disconnect: "断开连接",
    token: "代币",
    credits: "积分",
    pointDetails: "积分详情",
    incomeDetails: "收入详情",
    exchangeRecord: "交易记录",
    property: "财产",
    price: "价格",
    balance: "余额",
    value: "价值",
    exchange: "提现",
    exchange2: "申请提现",
    buy: "购买",
    exchange3: "兑换",
    vip: "VIP",
    noVip: "非VIP",
    creditsTips: "参与游戏需要积分，通过积分颁发奖励，积分和USDT可以随时双向兑换",
    vipTips: "参与游戏需要会员资格",
    confront: "对抗",
    topUp: "充值",
    debate: "辩论",
    hash: "哈希",
    result0: "待处理",
    result1: "通过",
    result2: "拒绝",
    remaining: "剩余",
    USDT: "USDT",
    MATIC: "MATIC",
    rechargeText: "USDT余额不足，请充值", // 余额不足
    approve: "检测到您的授权", // 余额不足
    payVip: "VIP只能购买两次", // 只能购买两次vip
  },
  Select: {
    all: "全部",
    game: "游戏",
    enableLists: "启用列表",
    disableLists: "禁用列表",
    stop: "停止",
    run: "激活",
    language: "切换语言",
    copylink: "复制链接",
    reportText: "举报",
  },
  terms: {
    userTerms: "用户条款，隐私政策",
    userCovenants: "用户契约",
    newsletterText: "接收Gogame的新闻通讯",
    agreeText: "我已阅读并同意",
  },
  login: {
    login: "登录",
    loginTips: "还没有账号？",
    register: "注册",
    registerTips: "已经有账号？",
    signIn: "钱包登录",
    signUp: "钱包注册",
    signOut: "钱包登出",
    skip: "跳过",
    SelectInteresting: "选择3个有趣的内容",
    SelectInterestingTips: "稍后可以随时修改",
    attentionUser: "关注有思想的用户",
    attentionUserTips: "获取更多创意",
    walletConnection: "钱包登录",
    selectWallet: "选择钱包",
    loginPlaceholder: "邀请码（必填）",
    requiredAddress: "请填写邀请码",
    selectNetwork: "选择网络",
    selectNetworkTips: "选择您使用Gogame的网络。一旦选择，就无法修改",
    installMetaMask: "您还未安装MetaMask",
    network: "钱包网络请切换至BNB Polygon",
  },
  // 公用文字
  DialogText: {
    tips: "提示",
    confirm: "确认",
    cancel: "取消",
    export: "导出",
    disable: "禁用",
    enable: "启用",
    disableTip: "确认禁用？",
    enableTip: "确认启用？",
    disableTitle: "禁用",
    enableTitle: "启用",
    saveTip: "保存吗？",
    saveTitle: "保存",
    Imgexpired: "图片已过期",
    accept: "接受",
    and: "和",
    total: "总共",
    next: "下一步",
    loading: "加载",
    approve: "批准",
    times: "次",
    cpoies: "份",
    developToast: "开发中，敬请期待",
  },
  Popconfirm: {
    confirm: "确认",
    cancel: "取消",
    refresh: "刷新",
    title: "确认删除吗？",
    saveChangeTip: "离开此页面未保存将删除您所做的所有更改。",
    saveChangeTitle: "保存更改",
    delAttrValTitle: "删除属性值",
    delAttrValTip: "您将无法恢复它。",
  },
  messageInfo: {
    switchLang: "语言已切换",
    lockIsOn: "大写已开启",
    successfully: "操作完成",
    deleteSuccess: "已删除",
    noData: "无数据",
    return: "返回",
    noPermissionLogin: "您无权登录。请联系管理员！",
    sessionOut: "您离开了一段时间，请重新登录！", // '登录会话过期，请重新登录!'
    serviceBusy: "服务器繁忙", // '服务器忙'
  },
  Confirm: {
    TIPS: "删除",
    more: "更多",
  },
  home: {
    title: "让世界看到不同的选择",
    main: "Plouto是一个集社交和游戏于一体的多链网络社交媒体平台。",
    VIDEOONE: "竞拍",
    VIDEOTWO: "对抗",
    VIDEOTHREE: "辩论",
    MoreFun: "更多乐趣",
    allHere: "尽在这里",
    ContactUs: "联系我们",
  },
  game: {
    hashTips: "祝您好运!!",
    confrontTips: "智慧的较量!",
    debateTips: "观点的碰撞!",
    moreTips: "敬请期待...",
    gifts: "会员福利",
    giftsTips: "会员专享，超值福利！",
    auction: "竞拍",
    auctionTips: "同创新精彩",
    game1: "当前奖池",
    game2: "去邀请好友",
    game3: "我的哈希值",
    game4: "游戏规则",
    game5: "进行中",
    game6: "已结束",
    confrontRule:
      "游戏分红、蓝双方，会员在参与对抗时，必须选择一方进行投注，最低投注额为2积分，可获得随机的开奖码，每次投注金额都会划分3个奖池中。45%进总奖池，20%进红奖池，20%进蓝奖池，5%平台服务费，5%发起人奖励，3%直推奖励，2%间推奖励。<br/>累计投注10000积分，红、蓝奖池加权平分给红蓝双方，连续分红10次或5天时间到期就会进行开奖。<br/>随机抽取一等奖1名  独中总奖池25%金额，<br/>抽取二等奖10名 加权平分总奖池20%金额，<br/>抽取三等奖40名 加权平分总奖池20%金额，<br/>剩余35%奖金加权平分给一等奖中奖者支持的红方或蓝方。<br/>项目发起方；钻石会员+200积分+团队人数100人，才有资格向平台发起。项目每天一个，最多7个项目同时在线，如果有超出的项目可排队等候。<br/>项目时间；为期5天。每个项目都有时间限制，5天为之最长期限。如果项目在5天内完成10次分红，可以提前开奖。",
    pay: "购买成功",
  },
  ranking: {
    hash: "哈希榜",
    debate: "观点榜",
    confront: "对抗榜",
    question: "问题排队榜",
    money: "奖金榜",
  },
  user: {
    user1: "关注者",
    user2: "正在关注",
    user3: "我的收益",
    user4: "查看收益明细",
    user5: "我的邀请",
    user6: "查看我的团队",
    user7: "我的资产",
    user8: "会员等级",
    user9: "购买",
    user10: "暂无数据",
    user11: "我参与的",
    user12: "我发起的",
    user13: "分红",
    user14: "VIP购买直推奖",
    user15: "VIP购买间推奖",
    user16: "游戏下注间推奖",
    user17: "游戏下注直推奖",
    user18: "对抗(派彩收益) ",
    user19: "对抗(项目方收益)",
    user20: "辩论(派彩收益) ",
    user21: "辩论(项目方收益)",
    user22: "BT竞猜(项目方收益)",
    user23: "空投USDT",
    user24: "更换头像",
    user25: "昵称",
    user26: "请输入昵称",
    user27: "昵称为4-30位字符，不支持空格",
    user28: "审核失败",
    user29: "待审核",
    user30: "等待发布",
    user31: "结算中",
    user32: "总票数",
    user33: "辩论人数",
    user34: "已支持",
    user35: "提现USDT",
    user36: "团队收益",
    user37: "平级收益",
    user38: "倒计时结束后，您将无法享受佣金计酬，参与任何游戏即可增加倒计时。",
    user39: "对抗(退还创建金额)",
    user40: "投票(退还创建金额)",
    user41: "对抗创建项目",
    user42: "对抗投注",
    user43: "辩论创建项目",
    user44: "辩论投注",
    user45: "BT投注",
    user46: "对抗(红奖池收益)",
    user47: "对抗(蓝奖池收益)",
    user48: "对抗(一等奖)",
    user49: "对抗(二等奖)",
    user50: "对抗(三等奖)",
    user51: "直推奖",
    user52: "间推奖",
    user53: "竞拍增值收益",
    win: "胜出",
    count: "已投",
  },
  vip: {
    vip1: "当前会员等级",
    vip2: "您当前",
    vip3: "会员系数",
    vip4: "我发起的辩论",
    vip5: "即将开始",
    vip6: "发起对抗",
    vip7: "发起辩论",
    vip8: "普通会员",
    vip9: "获得所有游戏资格",
    vip10: "立即提升",
    vip11: "白银会员",
    vip12: "黄金会员",
    vip13: "钻石会员",
    vip14: "获得游戏发起资格",
    vip15: "已是最高系数",
    vip16: "进行中",
    vip19: "延时进行中",
    vip17: "已结束",
    vip18: "您还不是钻石会员，请先开通钻石会员",
    vip19: "权重系数是参与游戏的身份象征，也是分红的比重系数，等级越高，分红越多。<br/>参与项目发起只有钻石会员可以发起。",
    vip20: "不是会员",
    vip21: "团队等级",
    vip22: "您还不是会员，请先开通会员",
    vip23: "我发起的对抗",
  },
  sendVote: {
    sendVote1: "请写下您的问题提醒：禁止发起与政治，国家，毒品，枪支等有关的敏感话题。",
    sendVote2: "添加选项",
    sendVote3: "频道",
    sendVote4: "请输入积分",
    sendVote5: "有效期",
    sendVote6: "天",
    sendVote7: "选择有效期",
    sendVote8: "添加频道",
    sendVote9: "请输入内容",
    sendVote10: "请选择频道",
    sendVote11: "请选择有效期",
    sendVote12: "请上传图片",
    sendVote13: "发布成功;等待审核",
    sendVote14: "上传中... ",
    sendVote15: "上传失败",
    sendVote16: "自定义消耗积分",
    sendVote17: "你可以自定义参与问题者辩论所消耗积分(最低1积分)",
    sendVote18: "自定义辩论有效期",
    sendVote19: "你可以自定义辩论有效期",
    sendVote20: "发起辩论最大天数",
    sendVote21: "最低1积分",
    sendVote22: "你的积分不足$$$,请充值",
    sendVote23: "提交中...",
  },
  headerNavbar: {
    headerNavbar1: "邀请好友",
    headerNavbar2: "赚取高达10%的佣金",
    headerNavbar3: "当您的朋友购买或升级会员时，您可以获得佣金。",
    headerNavbar4: "规则 ",
    headerNavbar5: "您的总佣金",
    headerNavbar6: "您的佣金率",
    headerNavbar7: "已获得",
    headerNavbar8: "已邀请",
    headerNavbar9: "邀请连接",
    headerNavbar10: "地址",
    headerNavbar11: "昨日",
    headerNavbar12: "今日",
    headerNavbar13: "累计收益",
    headerNavbar14: "邀请",
    headerNavbar15: "邀请记录",
    headerNavbar16: "好友购买或升级会员，邀请人可获得好友购买或升级会员金额的30%返佣，间接邀请人获得5%，返佣为相应价值的积分。·有效用户指的是购买会员的用户。",
    headerNavbar17: "*有效好友指的是购买SBT笔的用户。",
    headerNavbar18: "个人中心",
    headerNavbar19: "邀请/返佣",
    headerNavbar20: "站内信",
    headerNavbar21: "隐藏资产",
    headerNavbar22: "隐藏和屏蔽",
    headerNavbar23: "投诉建议",
    headerNavbar24: "退出登录",
    headerNavbar25: "可提积分",
    headerNavbar26: "邀请二维码",
    headerNavbar27: "我的探索",
    headerNavbar28: "邀请码",
    headerNavbar29: "复制",
    headerNavbar30: "邀请规则",
  },
  payModal: {
    payModal1: "充值必须是整数",
    payModal2: "请输入充值金额",
    payModal3: "提交",
    payModal4: "充值金额不能为空",
    payModal5: "成为会员",
    payModal6: "成为会员可以参与游戏",
    payModal7: "系数",
    payModal8: "支付",
    payModal9: "余额不足",
    payModal10: "提现申请",
    payModal11: "请输入您要提现的积分数量",
    payModal12: "1积分=1USDT,10起提,5的倍数",
    payModal23: "提现审核周期24小时",
    payModal24: "每笔手续费$$$积分",
    payModal13: "请输入提现金额",
    payModal14: "充值金额大于等于20",
    payModal15: "请输入5的倍数",
    payModal16: "提现申请已提交",
    payModal17: "铸造中",
    payModal18: "区块确权中",
    payModal19: "铸造成功",
    payModal20: "提现金额不能为空",
    payModal21: "余额不足",
    payModal22: "数额额大于等于1",
  },
  mixin: {
    mixin1: "支付中",
    mixin2: "确权成功",
  },
  vuex: {
    vuex1: "钱包连接中",
    vuex2: "未安装小狐狸",
    vuex3: "检测到您未登录,请先登录",
    vuex4: "未登录",
  },
  request: {
    "-100": "服务器未注册",
    "-101": "配置不存在",
    "-102": "客户端参数错误",
    "-103": "积分不足",
    "-106": "服务器繁忙",
    "-107": "不支持的URI",
    "-108": "权限不足",
    "-109": "管理員已存在",
    "-110": "管理員不存在",
    "-111": "不在活动时间",
    "-112": "订单超时",
    "-114": "用户不存在",
    "-115": "游戏不存在",
    "-200": "无法连接认证服务器",
    "-201": "账号已登录",
    "-202": "服务器已达最大人数",
    "-203": "未开放此类型的登录",
    "-204": "账号格式错误",
    "-205": "密码格式错误",
    "-206": "请输入正确的邀请码",
    "-208": "账号已在其他设备登录",
    "-209": "账号无权限登录,请联系管理员",
    "-210": "账号不存在",
    "-211": "角色不存在",
    "-212": "玩家不存在",
    "-213": "邀请码不能为空",
    "-214": "邀请码不能是自己",
    "-215": "邀请码中存在自己",
    "-216": "邀请码",
    "-400": "无效的类型",
    "-401": "金额错误",
    "-402": "24小时只能申请一次",
    "-410": "金额只支持100的倍数",
    "-500": "24小时只能提现一次",
    "-600": "VIP等级不足",
    "-601": "单次下注已超过当前VIP限制",
    "-602": "累积下注已超过当前VIP限制",
    "-700": "团队伞下人数不足",
    "-800": "项目不存在",
    "-900": "服务未开启",
    "-1000": "VIP赠送积分不存在",
    "-1001": "VIP赠送积分已解锁",
    "-1002": "转化金额不足",
    "-10004": "竞拍服务未开启",
    "-10005": "不在竞价时间",
    "-10200": "抢单次数不足",
    "-10201": "订单已售罄",
    "-10202": "无效账户",
    "-10203": "订单超时",
    "-10204": "余额不足",
    "-20000": "服务器繁忙",
    "-20001": "客户端参数错误 ",
    "-20002": "不支持的URI",
    "-20003": "无权限",
    "-20004": "对抗服务未开启",
    "-20005": "服务不在开放时间 ",
    "-20006": "用户不存在",
    "-20100": "未登录",
    "-20200": "创建已达上限",
    "-20201": " 无法连接APP服务器",
    "-20202": "游戏未开始",
    "-20203": "阵营类型错误",
    "-20204": "下注金额错误",
    "-20205": "游戏不存在",
    10001: "操作失败",
    20001: "超过进行中的最大辩论数",
    20002: "超过单人发起进行中的最大辩论数",
    20003: "投票操作频繁，超过分钟次数限制",
    20004: "过期天数不在有效天数范围内",
    20013: "辩论不存在或辩论选项不存在",
    20023: "辩论已结束",
    20033: "辩论未开始",
    20043: "理由未填写或超出200字数限制",
    20202: "辩论维护中",
    request42: "登录已过期请重新登录",
  },
  confront: {
    confront1: "购买记录",
    confront2: "开奖名单",
    confront3: "奖励规则",
    confront4: "1份",
    confront5: "未中奖",
    confront6: "奖池",
    confront7: "买入",
    confront8: "分红",
    confront9: "中奖方",
    confront10: "我的奖励",
    confront11: "一等奖",
    confront12: "二等奖",
    confront13: "三等奖",
    confront14: "平分奖",
  },
  dk: {
    ydj: "一等奖",
    edj: "二等奖",
    sdj: "三等奖",
    pfj: "平分奖",
    fhjl: "分红奖励",
    zjdz: "中奖地址",
    kjm: "开奖码",
    jlje: "奖励金额",
    fqdk: "发起对抗",
    text1: "发起对抗需同时满足以下条件",
    text2: "仅限于钻石会员发起对抗游戏",
    text3: "一周只能发起一次对抗游戏",
    text4: "伞下必须满足100人",
    text5: "需缴纳200U",
    text6: "对抗游戏规则",
    text7: `用户选择红方或者蓝方，用积分购买（V）股，成交后获得交易哈希值。<br /><br />
    每次购买W股的积分会分为：45%进入总奖池，20%进入红方奖池，20%进入蓝方奖池，5%交易手续费，5%项目方，3%直推奖，2%间推奖。V股价2积分<br /><br />
    累计购买V股10000积分进行分红一次，依次累计分红10次或5天时间倒计时后开奖。<br /><br />
    V股累计购买10000积分，红蓝双方进行平分奖池奖金（20%的红蓝奖池，加权分红）<br /><br />
    累计分红10次后或5天时间到，进行抽奖。如果抽中红方哈希值中一等奖则进行以下奖项分配<br /><br />
    一等奖：1名 独得总奖池25%奖励 （一等奖为红方）<br /><br />
    二等奖：10名 平分总奖池20%奖励<br /><br />
    三等奖：40名 平分总奖池20%奖励<br /><br />
    所有红方平分剩余的35%奖励 （蓝方中奖同样）<br /><br />
    120小时满后也准时开奖。<br /><br />`,
    yjs: "已结束",
    dqjc: "当前奖池",
    jf: "积分",
    qyqhy: "去邀请好友",
    vg: "V股",
    hydj: "会员等级",
    dcgm: "单次限购",
    xs: "系数",
    qtxmrgs: "请填写买入股数",
    zjf: "总积分",
    xhjf: "消耗积分",
    mr: "买入",
    zw: "暂无",
    fhpm: "第几次分红",
    je: "金额",
    lx: "类型",
    gmje: "购买金额",
  },
  gift: {
    gift1: "会员福利大放送",
    gift2: "买",
    gift3: "送",
    gift4: "白银会员",
    gift5: "黄金会员",
    gift6: "钻石会员",
    gift7: "每日",
    gift8: "%收益，",
    gift9: "小时收益到账",
    gift10: "累计收益",
    gift11: "倍",
    gift12: "备注：",
    gift13: "以上赠送积分无法提现,收益积分可以参与游戏或者提现",
    gift14: "领取积分",
    gift15: "可领福利积分",
    gift16: "手续费",
    gift17: "实际到账",
    gift18: "立即领取",
    gift19: "尊敬的用户，您还未充值该会员",
    gift20: "暂无法领取会员福利",
    gift21: "最大领取积分",
    gift22: "剩余结算积分",
    gift23: "尊敬的",
    gift24: "您暂未开启福利积分领取",
    gift25: "开启领取积分",
    gift26: "待释放积分",
    gift27: "请输入领取积分",
    gift28: "领取成功",
  },
  auction: {
    check: "我的认购",
    sell: "我的托管",
    detail: "订单详情",
    title1: "待支付订单",
    title2: "待寄售订单",
    title3: "寄售中订单",
    title4: "已完成订单",
    auction1: "买入",
    auction2: "竞拍范围",
    auction3: "敬请期待",
  },
  orders: {
    check: "我的订单",
    detail: "订单详情",
    title1: "待支付订单",
    title2: "已完成订单",
    auction1: "买入",
    auction2: "竞拍范围",
    auction3: "敬请期待",
  },
};
